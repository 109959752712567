import http from "../http";

const faviconRequest = {
  getChecklessFavicon: async (domain) => {
    return await http({
      method: "post",
      url: `/graphql/checkless`,
      data: {
        query: `{
          Favicon(domain: "${domain}") {
            id
            name
            domain
          }
        }
        `,
      },
    });
  },

  getFavicon: async(domain) => {
    return await http({
      url:`/Favicon/collect/${domain}`
    })
  },
  getFaviconList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    const privateQueryStr= "recommended ValueType.eq true"
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                Favicon(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-createTime", queryStr: "${privateQueryStr}") {
                  data {
                    id
                    name
                    domain
                    recommendation
                  }
                  count
                  pageSize,
                  pageNo
                }
              }
              `,
      },
    });
  },
};

export default faviconRequest;
