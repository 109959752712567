import http from "../http";

const poetryRequest = {
  getPoetryList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                  Poetry(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-updateTime,-createTime") {
                    data {
                      id
                      name
                      section
                      isDeleted
                      createTime
                      updateTime
                      tags
                      author {
                        id
                        name
                      }
                    }
                    count
                    pageSize,
                    pageNo
                  }
                }
                `,
      },
    });
  },
  getPoetryIdList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                  Poetry(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-updateTime,-createTime") {
                    data {
                      id
                    }
                    count
                    pageSize,
                    pageNo
                  }
                }
                `,
      },
    });
  },
  getChecklessPoetry: async (id) => {
    return await http({
      method: "post",
      url: `/graphql/checkless`,
      data: {
        query: `{
          Poetry(id: "${id}") {
            id
            name
            section
            paragraphs
            isDeleted
            createTime
            updateTime
            tags
            author {
              id
              name
            }
          }
        }
        `,
      },
    });
  },
};

export default poetryRequest;
