export const domainReg =
  /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;

export const formatDomain = (domain: string) => {
  const str = (domain || "").trim().match(domainReg)?.[0] || "";

  return str.toLowerCase();
};

export const getSizesStr = (sizes: number) => {
  if (sizes === -1) {
    return "";
  }
  if (sizes === 0) {
    return "any";
  }

  return `${sizes}x${sizes}`;
};

export const questionList = [
  {
    label: "为什么获取不到网站图标",
    href: "https://vwood.xyz/blog/00c98c57-fe76-4f1c-b965-4bef09eb87a0",
  },
  {
    label: "怎么设置网站图标",
    href: "https://vwood.xyz/blog/7e36bf57-2ba4-4f50-81e1-4d0a861c936a",
  },
  {
    label: "设置不用类型的图标",
    href: "https://vwood.xyz/blog/c71d4e19-4c9e-421c-8da8-864f4e7e205a",
  },
];

export const formatImgUrl = (url: string) => {
  if (url.startsWith("http")) {
    return url;
  }

  return `https://file.vwood.xyz` + url;
}