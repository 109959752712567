import axios from "axios";

// const getImageTypeSubPrefix = (fileType: string): string => {

//     switch(fileType) {
//         case "image/x-icon":
//         case "image/icon":
//             return "ico";
//         case 
//     }
// }

export class Downloadutils {
    static download (src: string, fileType: string) {

        if (fileType === "image/x-icon" || fileType === "image/icon") {
            axios.get(src, {responseType: "arraybuffer"})
            .then((response) => {
                let blob = new Blob([response.data], { type: response.data.type })
                let url = URL.createObjectURL(blob)
                const node = document.createElement("a")
                node.href = url
                node.download = `favicon_${+new Date()}.ico`;
                node.style.display = "none";
                document.body.appendChild(node);
                node.click();
                document.body.removeChild(node);
            }).catch((err) => {
                console.error(err);
            })
        } else {
            axios.get(src, {responseType: "blob"})
            .then((response) => {
                let blob = new Blob([response.data], { type: response.data.type })
                let url = URL.createObjectURL(blob)
                const node = document.createElement("a")
                node.href = url
                node.download = `favicon_${+new Date()}`;
                node.style.display = "none";
                document.body.appendChild(node);
                node.click();
                document.body.removeChild(node);
            }).catch((err) => {
                console.error(err);
            })
        }
    }
}