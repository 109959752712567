import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Request } from "~/http/request";

import { withRouter } from "next/router";

import { Button, Input, Popover, Table, message } from "antd";
import { Favicon, FaviconItem } from "./types";
import { WithRouterProps } from "next/dist/client/with-router";
import { Downloadutils } from "~/common/utils/downloadUtils";
import { formatDomain, formatImgUrl, questionList } from "./utils";
import Head from "next/head";
import { SITE_TITLE, getQueryObj } from "~/utils";
import { GiscusComment } from "~/components/GiscusComment";
import styles from "./style.module.scss";

interface Props extends WithRouterProps { }

const FaviconPage: FC<Props> = (props) => {
  const [domain, setDomain] = useState<string>(() => {
    const urlObj = getQueryObj(props.router?.asPath);
    const domain = urlObj.domain;
    if (!domain) {
      return "";
    }
    if (Array.isArray(domain)) {
      return formatDomain(domain[0]);
    }
    return formatDomain(domain);
  });
  const [favicon, setFavicon] = useState<Favicon>();
  const [loading, setLoading] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");

  const getFavicon = useCallback(
    async (_domain: string = domain) => {
      try {
        setErrorInfo("");
        setLoading(true);
        setFavicon(undefined);
        if (_domain) {
          const response = await Request.getFavicon(_domain);
          // @ts-ignore
          if (response.success) {
            const favicon = response.data || {};
            favicon.items = (favicon.items || []).sort(
              (a: FaviconItem, b: FaviconItem) => a.sizes - b.sizes
            );
            setFavicon(favicon);
          } else {
            setErrorInfo("未获取到数据");
          }
        }
      } catch (err) {
        console.error(err);
        message.error("请求错误");
      } finally {
        setLoading(false);
      }
    },
    [domain]
  );

  const handleDoaminChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value.trim());
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const domain = formatDomain(e.clipboardData.getData("text"));
    setTimeout(() => {
      setDomain(domain);
    });
  };

  const onSearch = () => {
    if (domain) {
      const _domain = formatDomain(domain);
      if (_domain) {
        if (_domain !== domain) {
          setDomain(_domain);
        }
        props.router.replace(props.router.pathname + "?domain=" + _domain);
        getFavicon(_domain);
      } else {
        setErrorInfo("域名/网站地址格式错误");
      }
    } else if (favicon) {
      props.router.replace(props.router.pathname);
      setFavicon(undefined);
    }
  };

  const handleDownload = useCallback(
    (row: FaviconItem) => {
      Downloadutils.download(formatImgUrl(row.url), row.fileType);
    },
    [favicon]
  );

  useEffect(() => {
    onSearch();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: "图标",
        dataIndex: "url",
        render: (url: string) => (
          <img
            src={formatImgUrl(url)}
            loading="lazy"
            className={styles.image}
          />
        ),
      },
      {
        title: "类型",
        dataIndex: "fileType",
      },
      {
        title: "尺寸",
        dataIndex: "sizes",
        render: (sizes: number) => {
          if (sizes === -1) {
            return "-";
          }
          if (sizes === 0) {
            return "any";
          }
          return `${sizes}x${sizes}`;
        },
      },
      {
        title: "rel",
        dataIndex: "rel",
        render: (rel: string) => (
          <div style={{ whiteSpace: "nowrap" }}>{rel}</div>
        ),
      },
      {
        title: "",
        dataIndex: "actions",
        render: (_, row: FaviconItem) => (
          <Button
            type="link"
            id="el-home-download"
            onClick={() => handleDownload(row)}
          >
            下载
          </Button>
        ),
      },
    ];
  }, [favicon]);

  return (
    <div className={styles.container}>
      <Head>
        <title>{SITE_TITLE}</title>
      </Head>
      <div className={styles.main}>
        <div className={styles.header}>
          <h1 className={styles.title}>快速获取站点Favicon图标</h1>
          <p>输入域名或地址就可获取站点的favicon图标</p>
        </div>
        <div className={styles.search}>
          <Input.Search
            size="large"
            placeholder="输入域名/网址"
            enterButton="获取"
            name="domain"
            value={domain}
            onPaste={handlePaste}
            // onBlur={handleSearchBlur}
            onSearch={onSearch}
            onChange={handleDoaminChange}
            loading={loading}
            style={{ width: "100%" }}
          />
          {errorInfo ? (
            <p className={styles["error-info"]}>{errorInfo}</p>
          ) : null}
        </div>
        <div className={styles.content}>
          <div>
            <div className={styles["meta-info"]}>
              <div className={styles["meta-info-item"]}>
                <span className={styles["item-label"]}>域名: </span>
                <span className={styles["item-value"]}>
                  <a href={"//" + favicon?.domain} target="_blank">
                    {favicon?.domain}
                  </a>
                </span>
              </div>
              {favicon?.description ? (
                <div className={styles["meta-info-item"]}>
                  <span className={styles["item-label"]}>描述: </span>
                  <span className={styles["item-value"]}>
                    {favicon?.description}
                  </span>
                </div>
              ) : null}
              {favicon?.keywords ? (
                <div className={styles["meta-info-item"]}>
                  <span className={styles["item-label"]}>关键词: </span>
                  <span className={styles["item-value"]}>
                    {favicon?.keywords}
                  </span>
                </div>
              ) : null}
            </div>
            <div className={styles.table}>
              <Table
                columns={columns}
                dataSource={favicon?.items || []}
                pagination={false}
                size="small"
                loading={{
                  spinning: loading,
                  tip: "数据获取中..",
                }}
                bordered
              ></Table>
            </div>

            <div className={styles["questions"]}>
              <ul>
                {questionList.map((item) => (
                  <ol key={item.href} className={styles["question-item"]}>
                    <a href={item.href} title={item.label} target="_blank">
                      <h2>
                        {item.label}
                      </h2>
                    </a>
                  </ol>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className={styles.commment}>
        <GiscusComment />
      </section>
    </div>
  );
};

export default withRouter(FaviconPage);
