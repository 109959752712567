import http from "../http";
import poetryRequest from "./poetry";
import faviconRequest from "./favicon";

const PRIVATE_ARTICLE = [
  "dcf7735f-1932-45d0-bce2-f28d4f4f31d1", // 个人项目
  "3f166e04-5e23-47c4-a8bb-c9e6c812ba34", // 关于
];

// 好文推荐bizTypeId
const my_recommend_bizTypeId = "b5126ddd-e1ce-4b38-9a9e-c95d09818fb5"
// vwood站点相关bizTypeId
const vwood_site_bizTypeId = "e6b5adf4-d1b7-40f3-94e3-e3fc55d007c2";

// 首页排除的业务类型
const special_bizTypeIdList = [my_recommend_bizTypeId, vwood_site_bizTypeId]

const privateQueryStr = `bizTypeId ValueType.nin '${special_bizTypeIdList.join(",")}'`;

export const Request = {
  getArticleList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                Article(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-updateTime,-createTime", queryStr: "${privateQueryStr}") {
                  data {
                    id
                    name
                    description
                    content
                    isDeleted
                    createTime
                    updateTime
                    likeCount
                    viewCount
                    operatorId
                    tags
                    creativeType
                    headerImg
                    operator {
                      id
                      name
                    }
                    url
                  }
                  count
                  pageSize,
                  pageNo
                }
              }
              `,
      },
    });
  },
  getLatestArticleList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                Article(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-createTime", queryStr: "${privateQueryStr}") {
                  data {
                    id
                    name
                  }
                }
              }
              `,
      },
    });
  },
  // 好文推荐
  getArticleRecommendList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    const queryStr = `bizTypeId ValueType.eq '${my_recommend_bizTypeId}'`;
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                Article(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-updateTime,-createTime", queryStr: "${queryStr}") {
                  data {
                    id
                    name
                    description
                    createTime
                    updateTime
                    operatorId
                    tags
                    creativeType
                    operator {
                      id
                      name
                    }
                    url
                  }
                  count
                  pageSize,
                  pageNo
                }
              }
              `,
      },
    });
  },

  getChecklessArticle: async (id) => {
    return await http({
      method: "post",
      url: `/graphql/checkless`,
      data: {
        query: `{
          Article(id: "${id}") {
            id
            name
            content
            category {
              id
              name
            }
            description
            isDeleted
            createTime
            updateTime
            likeCount
            viewCount
            operatorId
            tags
            headerImg
            creativeType
            operator {
              id
              name
              avatar
              description
              github
              email
            }
          }
        }
        `,
      },
    });
  },
  getLatestArticleListByOperator: async ({
    pageNo = 1,
    pageSize = 5,
    operatorId,
  } = {}) => {
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                Article(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-createTime", queryStr: "operator.id ValueType.eq '${operatorId}' and ${privateQueryStr}") {
                  data {
                    id
                    name
                  }
                }
              }
              `,
      },
    });
  },
  getChecklessTinyCode: async (id) => {
    return await http({
      method: "post",
      url: `/graphql/checkless`,
      data: {
        query: `{
          TinyCode(id: "${id}") {
            id
            name
            scriptType
            script
            cssType
            css
            htmlType
            html
            createTime
            updateTime
            operator {
              id
              name
              avatar
            }
          }
        }
        `,
      },
    });
  },

  getTinyCodeList: async ({ pageNo = 1, pageSize = 10 } = {}) => {
    return await http({
      method: "post",
      url: "/graphql/checkless/list",
      data: {
        query: `{
                TinyCode(pageNo: ${pageNo}, pageSize: ${pageSize}, sorts: "-updateTime,-createTime", queryStr: "${privateQueryStr}") {
                  data {
                    id
                    name
                    scriptType
                    script
                    cssType
                    css
                    htmlType
                    html
                    createTime
                    updateTime
                    operator {
                      id
                      name
                      avatar
                    }
                  }
                  count
                  pageSize,
                  pageNo
                }
              }
              `,
      },
    });
  },
  ...poetryRequest,
  ...faviconRequest
};
